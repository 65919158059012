import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { useDashboardCardStyles } from "./styles";

const PharmacyDashboard = () => {
  const classes = useDashboardCardStyles();

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Add Medication to Hatzoloh's inventory
          </Typography>
          <Typography component="div" color="textSecondary">
            Add medication Hatzoloh's inventory, by entering the lot number,
            expiry date and the quantity of medication available in the lot.
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/inventory/create`}
            size="large"
            color="secondary"
            variant="contained"
          >
            Add Medication
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            View Medication in Hatzoloh's inventory
          </Typography>
          <Typography component="div" color="textSecondary">
            View medications that are in Hatzoloh's inventory. You can see what
            quantity has been distributed, used or returned.
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/inventory/show`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Medication
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default PharmacyDashboard;
