import {
  Edit,
  FormDataConsumer,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";

const FormSaveToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const UserEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("User Updated");
    redirect("/users");
  };

  return (
    <Edit {...props} title="Edit User" onSuccess={onSuccess} undoable={false}>
      <SimpleForm toolbar={<FormSaveToolbar />}>
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput
          source="email"
          validate={[required()]}
          type="email"
          parse={(v) => v.toLowerCase()}
          helperText="Please contact tzvibell@gmail.com to change a user's email address"
          disabled
        />
        <SelectInput
          source="role"
          choices={[
            { id: "provider", name: "Provider" },
            { id: "pharmacy", name: "Pharmacy" },
            { id: "admin", name: "Admin" },
            // { id: "auditor", name: "Auditor" },
          ]}
          validate={[required()]}
          helperText="Please contact tzvibell@gmail.com to change a user's role"
          disabled
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === "provider" && (
              <SelectInput
                source="providerType"
                {...rest}
                choices={[
                  { id: "bls", name: "BLS" },
                  { id: "als", name: "ALS" },
                ]}
                validate={[required()]}
                helperText="Please contact tzvibell@gmail.com to change a user's provider type"
                disabled
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
