import axios from "axios";
import { useState } from "react";
import {
  Create,
  FormDataConsumer,
  Loading,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from "react-admin";

const FormSaveToolbar = ({ invalid, ...rest }) => (
  <Toolbar {...rest}>
    <SaveButton disabled={invalid} />
  </Toolbar>
);
export const UserCreate = (props) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = async ({ data }) => {
    try {
      setLoading(true);
      await axios({
        method: "post",
        url: "https://enw20xaez7qyley.m.pipedream.net",
        data: {
          email: data.email,
          role: data.role,
          id: data.id.toString(),
          providerType: data.providerType,
        },
      });
      notify("User Created");
    } catch (error) {
      console.log("An error occurred creating a user in Firebase: ", error);
      notify("An error occurred creating a user.");
    } finally {
      setLoading(false);
      redirect("/users");
    }
  };

  if (loading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Getting everything together"
      />
    );
  }

  return (
    <Create {...props} title="Create User" onSuccess={onSuccess}>
      <SimpleForm toolbar={<FormSaveToolbar />}>
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput
          source="email"
          validate={[required()]}
          type="email"
          parse={(v) => v.toLowerCase()}
        />
        <SelectInput
          source="role"
          choices={[
            { id: "provider", name: "Provider" },
            { id: "pharmacy", name: "Pharmacy" },
            { id: "admin", name: "Admin" },
            // { id: "auditor", name: "Auditor" },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === "provider" && (
              <SelectInput
                source="providerType"
                {...rest}
                choices={[
                  { id: "bls", name: "BLS" },
                  { id: "als", name: "ALS" },
                ]}
                validate={[required()]}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
