import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Notification, useLogin, useNotify, useRedirect } from "react-admin";
import { useLocation } from "react-router-dom";
import { theme } from "../App";
import { FirebaseApp } from "../firebase";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1.5em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const PasswordResetForm = (props) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const login = useLogin();
  const search = useLocation().search;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const actionCode = new URLSearchParams(search).get("oobCode");

  useEffect(() => {
    if (actionCode) {
      FirebaseApp()
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          setEmail(email);
        })
        .catch((error) => {
          console.log({ error });
          notify("Link invalid. Please request a new reset-password email.");
          redirect("/login");
        });
    } else {
      notify("Who dis?");
      redirect("/login");
    }
  }, [actionCode, notify, redirect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    FirebaseApp()
      .auth()
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        login({ email, password }, "/?reload=true");
        notify("Password Created!");
      })
      .catch((error) => {
        console.log({ error });
        notify(
          "Something went wrong. Please request a new reset-password email.",
        );
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img
              src="/web_logo.svg"
              alt="logo"
              width="100px"
              style={{ alignSelf: "center" }}
            />
          </div>
          <div className={classes.form}>
            <h2 style={{ color: "#312783" }}>Medication Management</h2>
            <div className={classes.input}>
              <TextField
                name="email"
                value={email}
                disabled={true}
                label="E-mail"
                type="email"
                autoComplete="email"
                required
                fullWidth
              />
            </div>
            <div className={classes.input}>
              <TextField
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="New Password"
                type="password"
                autoComplete="new-password"
                autoFocus
                disabled={loading}
                required
                fullWidth
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              Create Password
            </Button>
          </CardActions>
        </Card>
        <Notification />
      </div>
    </form>
  );
};

const PasswordReset = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <PasswordResetForm {...props} />
  </ThemeProvider>
);

export default PasswordReset;
