import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React, { useState } from "react";
import { Notification, useLogin, useNotify, useTranslate } from "react-admin";
import { Field, withTypes } from "react-final-form";
import { theme } from "../../App";
import ForgotPasswordButton from "./CustomForgotPassword";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1.5em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const LoginForm = (props) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, "/").catch((error) => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
        "warning",
        {
          _:
            typeof error === "string"
              ? error
              : error && error.message
                ? error.message
                : undefined,
        },
      );
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src="/web_logo.svg"
                  alt="logo"
                  width="100px"
                  style={{ alignSelf: "center" }}
                />
              </div>
              <div className={classes.form}>
                <h2 style={{ color: "#312783" }}>Medication Management</h2>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    // @ts-ignore
                    component={renderInput}
                    label="E-mail"
                    type="email"
                    autoComplete="email"
                    disabled={loading}
                    required
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("ra.auth.password")}
                    type="password"
                    autoComplete="existing-password"
                    disabled={loading}
                    required
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
              <div style={{ textAlign: "center", paddingBottom: 20 }}>
                <ForgotPasswordButton {...props} />
              </div>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

const Login = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <LoginForm {...props} />
  </ThemeProvider>
);

export default Login;
