import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFowcef_ayXTEKHhzIpiVL6IBS921jJtc",
  authDomain: "medication-management-be2e1.firebaseapp.com",
  projectId: "medication-management-be2e1",
  storageBucket: "medication-management-be2e1.appspot.com",
  messagingSenderId: "466618890851",
  appId: "1:466618890851:web:5ab386345fe4038c711656",
};

let firebaseApp;

export const FirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
  }

  return firebaseApp;
};
