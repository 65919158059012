import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  Labeled,
  List,
  Pagination,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useInput,
  useNotify,
  useRedirect,
} from "react-admin";

const useFilterStyles = makeStyles((theme) => ({
  input: {
    minWidth: 200,
  },
}));

const CheckboxInput = (props) => {
  const { input } = useInput(props);
  return (
    <Labeled label={props.label}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox {...input} />
        <Typography component="div">{props.label}</Typography>
      </div>
    </Labeled>
  );
};

const MedicationFilters = (props) => {
  const classes = useFilterStyles();

  return (
    <Filter {...props}>
      <SelectInput
        label="Provider Level"
        source="providerLevel"
        choices={[
          { id: "als", name: "ALS" },
          { id: "bls", name: "BLS" },
        ]}
        alwaysOn
        emptyText="Show All"
      />
      <SelectInput
        label="Controlled Substance"
        source="controlledSubstance"
        choices={[
          { id: true, name: "Yes" },
          { id: false, name: "No" },
        ]}
        alwaysOn
        emptyText="Show All"
        className={classes.input}
      />
      <SelectInput
        label="BLS Restricted"
        source="blsRestricted"
        choices={[
          { id: true, name: "Yes" },
          { id: false, name: "No" },
        ]}
        alwaysOn
        emptyText="Show All"
        className={classes.input}
      />
    </Filter>
  );
};

const FormSaveToolbar = ({ invalid, ...rest }) => (
  <Toolbar {...rest}>
    <SaveButton disabled={invalid} />
  </Toolbar>
);

export const MedicationCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      {...props}
      title="Add a New Medication"
      onSuccess={() => {
        notify("Medication Added");
        redirect("/medications");
      }}
    >
      <SimpleForm toolbar={<FormSaveToolbar />}>
        <TextInput
          source="name"
          label="Medication Name"
          validate={[required()]}
        />
        <TextInput source="concentration" label="Medication Concentration" />
        <CheckboxInput
          source="controlledSubstance"
          label="Controlled Substance"
          type="checkbox"
        />
        <CheckboxInput
          source="blsRestricted"
          label="BLS Restricted"
          type="checkbox"
        />
        <SelectInput
          source="providerLevel"
          choices={[
            { id: "bls", name: "BLS" },
            { id: "als", name: "ALS" },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export const MedicationEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      {...props}
      title="Edit Medication"
      onSuccess={() => {
        notify("Medication Updated");
        redirect("/medications");
      }}
      undoable={false}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label="Medication Name"
          validate={[required()]}
        />
        <TextInput source="concentration" label="Medication Concentration" />
        <CheckboxInput
          source="controlledSubstance"
          label="Controlled Substance"
          type="checkbox"
        />
        <CheckboxInput
          source="blsRestricted"
          label="BLS Restricted"
          type="checkbox"
        />
        <SelectInput
          source="providerLevel"
          choices={[
            { id: "bls", name: "BLS" },
            { id: "als", name: "ALS" },
          ]}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export const MedicationList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      title="Medications"
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />}
      perPage={25}
      filters={props.permissions !== "pharmacy" && <MedicationFilters />}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="concentration" />
        {props.permissions !== "pharmacy" && (
          <FunctionField
            render={(record) => record.providerLevel.toUpperCase()}
            label="Provider Level"
          />
        )}
        {props.permissions !== "pharmacy" && (
          <FunctionField
            render={(record) => (record.controlledSubstance ? "Yes" : "No")}
            label="Controlled Substance"
          />
        )}
        {props.permissions !== "pharmacy" && (
          <FunctionField
            render={(record) => (record.blsRestricted ? "Yes" : "No")}
            label="BLS Restricted"
          />
        )}
        {props.permissions !== "pharmacy" && (
          <DateField
            source="created_at"
            label="Date added"
            options={{
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
          />
        )}
        {props.permissions === "admin" && <EditButton />}
      </Datagrid>
    </List>
  );
};
