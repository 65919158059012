import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import pick from "lodash.pick";
import { useCallback, useEffect, useState } from "react";
import {
  DateInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  Labeled,
  required,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField as TextFieldRA,
  TextInput,
  Toolbar,
  TopToolbar,
  useEditController,
  useGetIdentity,
  useInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { authGQLClient } from "../../apolloClient";

const GET_MEDICATION_RESTRICTIONS = gql`
  query GetMedicationIsControlledSubstance($inventoryId: Int!) {
    medications(
      where: {
        inventories: { distributedInventories: { id: { _eq: $inventoryId } } }
      }
    ) {
      id
      controlledSubstance
      blsRestricted
    }
  }
`;

const GET_USER_INVENTORY_DATA = gql`
  query GetUserMedicationQuantities($providerId: Int!, $inventoryId: Int!) {
    distributedInventory(
      where: {
        lostDate: { _is_null: true }
        returnedDate: { _is_null: true }
        used: { _eq: false }
        wasted: { _eq: false }
        inventoryId: { _eq: $inventoryId }
        providerId: { _eq: $providerId }
      }
    ) {
      id
    }
  }
`;

const UPDATE_DISTRIBUTED_INVENTORY = gql`
  mutation UpdateDistributedInventory(
    $ids: [Int!]
    $updateObject: distributedInventory_set_input!
  ) {
    update_distributedInventory(
      where: { id: { _in: $ids } }
      _set: $updateObject
    ) {
      returning {
        updated_at
        id
      }
    }
  }
`;

const expired = (expiredDate) => new Date(expiredDate) < new Date() && "red";

const CheckboxInput = (props) => {
  const { input } = useInput(props);
  return (
    <Labeled label={props.label}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          {...input}
          {...(props.name === "returnedDateCheckbox" && {
            checked: props.checked,
          })}
          disabled={!!props.disabled}
        />
        <Typography component="div">{props.label}</Typography>
      </div>
    </Labeled>
  );
};

const EditActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const FormSaveToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DistributedInventoryEdit = (props) => {
  const controllerProps = useEditController(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const { identity } = useGetIdentity();

  const [returnChecked, setReturnChecked] = useState(
    !!controllerProps?.record?.returnedDate,
  );

  const [lostChecked, setLostChecked] = useState(
    !!controllerProps?.record?.lostDate,
  );

  const [updateQuantity, setUpdateQuantity] = useState("1");

  const [getUserInventoryData, { data: userInventoryData }] = useLazyQuery(
    GET_USER_INVENTORY_DATA,
    {
      variables: {
        inventoryId: controllerProps?.record?.inventoryId,
        providerId: controllerProps?.record?.providerId,
      },
      client: authGQLClient,
    },
  );

  const [updateDistributedInventory] = useMutation(
    UPDATE_DISTRIBUTED_INVENTORY,
    {
      client: authGQLClient,
    },
  );

  useEffect(() => {
    if (!!controllerProps?.record?.returnedDate) {
      setReturnChecked(true);
    }
    if (!!controllerProps?.record?.lostDate) {
      setLostChecked(true);
    }
    if (!!controllerProps?.record?.inventoryId) {
      getUserInventoryData();
    }
  }, [controllerProps?.record, getUserInventoryData]);

  const { data: medicationsData } = useQuery(GET_MEDICATION_RESTRICTIONS, {
    variables: { inventoryId: props.id },
    client: authGQLClient,
  });

  const {
    controlledSubstance: isControlledSubstance,
    blsRestricted: isBlsRestricted,
  } = medicationsData?.medications?.[0] || {};

  const inventoryLength =
    userInventoryData?.distributedInventory?.map((obj) => obj?.id)?.length || 1;

  const isUserAlsProvider = identity?.providerType === "als";

  const save = useCallback(
    async (values) => {
      try {
        if (Number(updateQuantity) < 1) {
          return;
        }

        const inventoryIds = await authGQLClient.query({
          query: GET_USER_INVENTORY_DATA,
          variables: {
            inventoryId: controllerProps?.record?.inventoryId,
            providerId: controllerProps?.record?.providerId,
          },
          fetchPolicy: "network-only",
        });

        const distributedInventory =
          inventoryIds?.data?.distributedInventory?.map((obj) => obj?.id);

        const updateIds = distributedInventory.length
          ? distributedInventory?.slice(0, updateQuantity)
          : [props.id];

        const updateObject = pick(values, [
          "used",
          "wasted",
          "lostDate",
          "returnedDate",
          "usedDate",
          "usedRunNumber",
          "usedDosage",
          "wastedAmount",
        ]);

        const updatedInventory = await updateDistributedInventory({
          variables: {
            ids: updateIds,
            updateObject: {
              updatedById: identity?.id,
              updatedByRole: identity?.role,
              ...updateObject,
            },
          },
        });
        if (
          updatedInventory?.data?.update_distributedInventory?.returning?.length
        ) {
          notify("Medications Updated");
          redirect("/distributedInventory");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      controllerProps?.record?.inventoryId,
      controllerProps?.record?.providerId,
      identity?.id,
      identity?.role,
      notify,
      props.id,
      redirect,
      updateDistributedInventory,
      updateQuantity,
    ],
  );

  return (
    <Edit
      {...props}
      title="Updating Medication"
      actions={
        props.permissions !== "provider" && (
          <EditActions permissions={props.permissions} />
        )
      }
      undoable={false}
      onSuccess={() => {
        notify("Medication Updated");
        redirect("/");
      }}
    >
      <SimpleForm save={save} toolbar={<FormSaveToolbar />}>
        <FunctionField
          label="Medication"
          render={({ inventory }) => {
            const { name, concentration } = inventory?.medication || {};
            return `${name}${concentration ? " - " + concentration : ""}`;
          }}
        />
        <TextFieldRA label="Lot Number" source="inventory.lotNumber" />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <CheckboxInput
              source="used"
              label="Used"
              type="checkbox"
              disabled={returnChecked || lostChecked}
              {...rest}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.used &&
            (isControlledSubstance ||
              (!isUserAlsProvider && isBlsRestricted)) && (
              <DateInput source="usedDate" validate={[required()]} {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.used &&
            (isControlledSubstance ||
              (!isUserAlsProvider && isBlsRestricted)) && (
              <TextInput
                source="usedRunNumber"
                validate={[required()]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.used &&
            (isControlledSubstance ||
              (!isUserAlsProvider && isBlsRestricted)) && (
              <TextInput
                source="usedDosage"
                validate={[required()]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.used &&
            (isControlledSubstance ||
              (!isUserAlsProvider && isBlsRestricted)) && (
              <TextInput
                multiline
                source="wastedAmount"
                // validate={[required()]}
                {...rest}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isControlledSubstance && (
              <CheckboxInput
                checked={!!lostChecked}
                name="lostDateCheckbox"
                onChange={(e) => setLostChecked(e.target.checked)}
                label="Lost"
                type="checkbox"
                disabled={formData.used || formData.returnedDate}
                {...rest}
              />
            )
          }
        </FormDataConsumer>

        {lostChecked && (
          <DateInput
            source="lostDate"
            label="Lost Date"
            validate={[required()]}
          />
        )}

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (isControlledSubstance && props.permissions === "provider") {
              return (
                <p>
                  Only the Administrator can mark controlled substances as
                  returned
                </p>
              );
            }
            return (
              ((isControlledSubstance && props.permissions === "admin") ||
                !isControlledSubstance) && (
                <CheckboxInput
                  checked={!!returnChecked}
                  name="returnedDateCheckbox"
                  onChange={(e) => setReturnChecked(e.target.checked)}
                  label={
                    isControlledSubstance ? "Returned" : "Returned or Discarded"
                  }
                  type="checkbox"
                  disabled={formData.used}
                  {...rest}
                />
              )
            );
          }}
        </FormDataConsumer>

        {returnChecked && (
          <DateInput
            source="returnedDate"
            label={
              isControlledSubstance
                ? "Returned Date"
                : "Returned or Discarded Date"
            }
            validate={[required()]}
          />
        )}

        <FunctionField
          label="Expiry Date"
          render={({ inventory }) => (
            <div style={{ color: expired(inventory?.expiryDate) }}>
              {inventory?.expiryDate}
            </div>
          )}
        />

        <div style={{ padding: 15, paddingLeft: 0, width: 150 }}>
          <TextField
            name="quantity"
            type="number"
            label="Quantity"
            validate={[required()]}
            value={updateQuantity}
            onChange={(e) => {
              if (Number(e.target.value) > inventoryLength) {
                return;
              }
              setUpdateQuantity(e.target.value);
            }}
            min={1}
            required={true}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};
