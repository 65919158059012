import { gql, useQuery } from "@apollo/client";
import {
  BooleanField,
  DateField,
  EditButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useGetIdentity,
} from "react-admin";
import { authGQLClient } from "../../apolloClient";

const GET_MEDICATION_RESTRICTIONS = gql`
  query GetMedicationIsControlledSubstance($inventoryId: Int!) {
    medications(
      where: {
        inventories: { distributedInventories: { id: { _eq: $inventoryId } } }
      }
    ) {
      id
      controlledSubstance
      blsRestricted
    }
  }
`;

const ShowActions = ({ basePath, data }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const DistributedInventoryShow = (props) => {
  const { identity } = useGetIdentity();
  const { data } = useQuery(GET_MEDICATION_RESTRICTIONS, {
    variables: { inventoryId: props.id },
    client: authGQLClient,
  });

  const {
    controlledSubstance: isControlledSubstance,
    blsRestricted: isBlsRestricted,
  } = data?.medications?.[0] || {};

  const isUserAlsProvider = identity?.providerType === "als";

  return (
    <Show
      {...props}
      actions={
        props.permissions !== "provider" && (
          <ShowActions permissions={props.permissions} />
        )
      }
      title={props.permissions !== "provider" ? "My Medications" : " "}
    >
      <SimpleShowLayout>
        <FunctionField
          label="Provider Name"
          render={({ user }) => {
            const { firstName, lastName } = user || {};
            return `${firstName} ${lastName}`;
          }}
        />
        <FunctionField
          label="Medication"
          render={({ inventory }) => {
            const { name, concentration } = inventory?.medication || {};
            return `${name}${concentration ? " - " + concentration : ""}`;
          }}
        />
        <TextField label="Lot Number" source="inventory.lotNumber" />
        <BooleanField source="used" />
        {(isControlledSubstance || (!isUserAlsProvider && isBlsRestricted)) && (
          <DateField
            source="usedDate"
            options={{
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
          />
        )}
        {(isControlledSubstance || (!isUserAlsProvider && isBlsRestricted)) && (
          <TextField source="usedRunNumber" />
        )}
        {(isControlledSubstance || (!isUserAlsProvider && isBlsRestricted)) && (
          <TextField source="usedDosage" />
        )}
        {(isControlledSubstance || (!isUserAlsProvider && isBlsRestricted)) && (
          <TextField source="wastedAmount" />
        )}
        <DateField
          source="returnedDate"
          options={{
            year: "numeric",
            month: "short",
            day: "numeric",
          }}
        />
        <DateField
          source="lostDate"
          options={{
            year: "numeric",
            month: "short",
            day: "numeric",
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};
