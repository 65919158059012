import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useGetIdentity } from "react-admin";
import { Link } from "react-router-dom";
import { useDashboardCardStyles } from "./styles";

const ProviderDashboard = () => {
  const classes = useDashboardCardStyles();
  const { identity } = useGetIdentity();

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Add New Medications
          </Typography>
          <Typography component="div" color="textSecondary">
            Add medication to your inventory, and get reminders before it
            expires.
            <Typography component="div" color="textSecondary">
              <i>
                {`${identity?.providerType === "als" ? "Controlled substances" : "BLS restricted medications"} can only be
                added by the administrator.`}
              </i>
            </Typography>
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/distributedInventory/create`}
            size="large"
            color="secondary"
            variant="contained"
          >
            Add Medication
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Update medications
          </Typography>
          <Typography component="div" color="textSecondary">
            Mark a medication in your inventory as used or returned.
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/distributedInventory`}
            size="large"
            color="secondary"
            variant="contained"
          >
            Update Medication
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            My Medications
          </Typography>
          <Typography component="div" color="textSecondary">
            View the medications in your inventory.
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/distributedInventory`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Medication
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Medical Directives{" "}
          </Typography>
          <Typography component="div" color="textSecondary">
            View Hatzoloh's medical directives
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.button}
            component={Link}
            to={`/protocols`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Directives
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default ProviderDashboard;
