import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { FirebaseApp } from "./firebase";

const getIdToken = () => {
  try {
    return FirebaseApp().auth().currentUser
      ? FirebaseApp().auth().currentUser.getIdToken()
      : Promise.resolve(null);
  } catch (error) {
    console.log("error in catch: ", error);
  }
};

const authLink = setContext((_, { headers }) => {
  return getIdToken()
    .then((token) => {
      // console.log("token: ", token);
      return {
        headers: {
          ...headers,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      };
    })
    .catch((error) => {
      console.log(error);
    });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = from([
  authLink,
  errorLink,
  new HttpLink({
    uri:
      process.env.REACT_APP_STAGE === "local"
        ? "https://med-management.hasura.app/v1/graphql"
        : "https://medication-management.herokuapp.com/v1/graphql",
  }),
]);

export const authGQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});
