import { gql } from "@apollo/client";
import { authGQLClient } from "./apolloClient";
import { FirebaseApp } from "./firebase";

export async function getProfile(fetchPolicy, user) {
  const query = gql`
    query getProfile {
      users(where: {email: {_eq: "${user}"}}) {
        id
        email
        firstName
        lastName
        providerType
        role
      }
    }
  `;

  const results = await authGQLClient
    .query({
      query,
      fetchPolicy,
    })
    .then(({ data }) => data.users)
    .catch((e) => console.log("error getting user: ", e));

  return results.length ? results[0] : null;
}

const checkAuth = () => {
  return !!FirebaseApp().auth().currentUser
    ? Promise.resolve()
    : Promise.reject();
};

export const authProvider = {
  checkAuth,

  login: async ({ email, password }) => {
    try {
      await FirebaseApp().auth().signInWithEmailAndPassword(email, password);

      const user = await getProfile("network-only", email);

      if (!user) {
        await FirebaseApp().auth().signOut();
        throw new Error("User doesn't exist");
      }
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("permissions", user.role);

      return user;
    } catch (error) {
      console.log(error);
      if (error.code === "auth/invalid-email") {
        console.log("invalid email");
        throw Error(
          "The email address is invalid. Please make sure the email address is entered in lowercase.",
        );
      }
      if (error.code === "auth/user-not-found") {
        throw Error("We don't know anyone with that email address");
      }
      if (error.code === "auth/wrong-password") {
        throw Error("Wrong email/password combination.");
      } else {
        throw error.message;
      }
    }
  },

  logout: () =>
    authGQLClient
      .resetStore()
      .then(() => {
        FirebaseApp().auth().signOut();
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");
      })
      .catch((error) => {
        console.log(error);
      }),

  checkError: ({ graphQLErrors, networkError }) => {
    console.log("checking errors: ", graphQLErrors, networkError);
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (
      graphQLErrors &&
      graphQLErrors[0].extensions.code === "validation-failed"
    ) {
      return checkAuth();
    }

    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const { id, firstName, lastName, providerType, role } = JSON.parse(
        localStorage.getItem("user"),
      );
      return Promise.resolve({ id, firstName, lastName, providerType, role });
    } catch (error) {
      console.log("error: ", error);
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    if (window.location.pathname === "/password-reset") {
      return Promise.resolve();
    }

    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
