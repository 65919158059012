import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FunctionField,
  List,
  Loading,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useGetIdentity,
} from "react-admin";
import { ListFields } from "./ListFields";

const expired = (expiredDate) => new Date(expiredDate) < new Date();

const DistributedInventoryFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Lot number"
        source="inventoryId"
        reference="inventory"
        alwaysOn
        allowEmpty
        perPage={150}
        {...(props.identity?.role === "provider"
          ? {
              filter: {
                "distributedInventories#providerId": props.identity?.id,
              },
            }
          : {})}
        filterToQuery={(searchText) => ({ lotNumber: searchText })}
      >
        <AutocompleteInput
          optionText="lotNumber"
          options={{ label: "Search By Lot Number" }}
          shouldRenderSuggestions={(value) => value?.length}
          resettable
          emptyText="Show All"
          allowEmpty
          alwaysOn
        />
      </ReferenceInput>
      <TextInput
        source="inventory#medication#name@_ilike,inventory#medication#concentration@_ilike"
        options={{ label: "Search By Medication" }}
        resettable
        alwaysOn
        allowEmpty
      />
      {(props.permissions === "admin" || props.permissions === "auditor") && [
        <ReferenceInput
          key="provider"
          label="Provider"
          source="providerId"
          reference="users"
          alwaysOn
          allowEmpty
          filter={{ role: "provider" }}
          sort={{ field: "providerType", order: "ASC" }}
          filterToQuery={(searchText) => ({
            "firstName,lastName": searchText,
          })}
        >
          <AutocompleteInput
            optionText={(user) =>
              user?.role === "provider" &&
              `${user?.firstName} ${user?.lastName}`
            }
            options={{ label: "Search By Provider" }}
            resettable
            shouldRenderSuggestions={(value) => value?.length}
            emptyText="All Providers"
          />
        </ReferenceInput>,
        <SelectInput
          key="controlledSubstances"
          source="inventory#medication#controlledSubstance"
          choices={[
            { id: true, name: "Controlled Substances" },
            { id: false, name: "Non-Controlled Substances" },
          ]}
          label="Controlled Substance"
          emptyText="Show All"
          alwaysOn
          style={{ width: 200 }}
        />,
        <SelectInput
          key="blsRestricted"
          source="inventory#medication#blsRestricted"
          choices={[
            { id: true, name: "BLS Restricted" },
            { id: false, name: "BLS Unrestricted" },
          ]}
          label="BLS Restricted"
          emptyText="Show All"
          alwaysOn
          style={{ width: 200 }}
        />,
      ]}
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 250,
  },
}));

export const DistributedInventoryList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const [view, setView] = useState("active");

  const handleChange = (event) => {
    setView(event.target.value);
  };

  if (identityLoading) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Getting everything together"
      />
    );
  }

  return (
    <div style={{ margin: 15 }}>
      {permissions === "admin" || permissions === "auditor" ? (
        <List
          {...props}
          filters={
            <DistributedInventoryFilters
              permissions={permissions}
              identity={identity}
            />
          }
          sort={{
            field: "returnedDate",
            order: "Desc",
          }}
          bulkActionButtons={false}
          actions={null}
          title="Distributed Inventory"
          exporter={false}
          pagination={
            <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
          }
          perPage={25}
        >
          <Datagrid
            rowClick="show"
            isRowSelectable={(record) =>
              record?.inventory?.medication?.controlledSubstance
            }
          >
            <TextField label="Lot Number" source="inventory.lotNumber" />
            <FunctionField
              label="Medication"
              render={({ inventory }) => {
                const { name, concentration } = inventory?.medication || {};
                return `${name}${concentration ? " - " + concentration : ""}`;
              }}
            />
            <FunctionField
              label="Provider Name"
              render={({ user }) => {
                const { firstName, lastName } = user || {};
                return `${firstName} ${lastName}`;
              }}
            />
            <FunctionField
              label="Status"
              render={(record) => {
                if (record.LostDate) {
                  return "Lost";
                }
                if (record.returnedDate) {
                  return "Returned";
                }
                if (record.used) {
                  return "Used";
                }
                if (
                  expired(record.inventory.expiryDate) &&
                  !record.returnedDate
                ) {
                  return "Expired - Not Returned";
                }
                if (
                  expired(record.inventory.expiryDate) &&
                  record.returnedDate
                ) {
                  return "Expired - Returned";
                }
                if (record.lostDate) {
                  return "Lost";
                } else {
                  return "Active";
                }
              }}
            />
          </Datagrid>
        </List>
      ) : (
        <>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={view}
              onChange={handleChange}
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="active">{`Show${
                view === "active" ? "ing " : " "
              }Active`}</MenuItem>
              <MenuItem value="expired">{`Show${
                view === "expired" ? "ing " : " "
              }Expired`}</MenuItem>
              <MenuItem value="used">{`Show${
                view === "used" ? "ing " : " "
              }Used`}</MenuItem>
              <MenuItem value="returned">{`Show${
                view === "returned" ? "ing " : " "
              }Returned`}</MenuItem>
              <MenuItem value="lost">{`Show Lost`}</MenuItem>
              {/* <MenuItem value="all">{`Show${
            view === "all" ? "ing " : " "
          }All`}</MenuItem> */}
            </Select>
          </FormControl>
          <List
            {...props}
            filters={
              <DistributedInventoryFilters
                permissions={permissions}
                identity={identity}
              />
            }
            filter={{
              ...(permissions === "provider"
                ? { distinct_on: "inventoryId" }
                : {}),
              providerId: identity.id,
              used: view === "used",
              returnedDate: {
                format: "hasura-raw-query",
                value: { _is_null: view !== "returned" },
              },
              lostDate: {
                format: "hasura-raw-query",
                value: { _is_null: view !== "lost" },
              },
              inventory: {
                format: "hasura-raw-query",
                value: {
                  expiryDate: {
                    ...(view === "expired"
                      ? { _lte: new Date() }
                      : { _gt: new Date() }),
                  },
                },
              },
            }}
            sort={{
              field: "inventoryId",
              order: "asc",
            }}
            bulkActionButtons={false}
            actions={null}
            title="My Medication"
            exporter={false}
            empty={<div>No Medications</div>}
            pagination={<div />}
            perPage={250}
          >
            <ListFields permissions={permissions} />
          </List>
        </>
      )}
    </div>
  );
};
