import { format } from "date-fns";
import { SimpleList } from "react-admin";

const expired = (expiredDate) => new Date(expiredDate) < new Date();

export const ListFields = (props) => {
  return (
    <SimpleList
      primaryText={(record) => {
        if (!record?.inventory) return null;
        return `${record.inventory.medication.name} - ${record.inventory.medication.concentration}`;
      }}
      secondaryText={(record) => {
        if (!record?.inventory) return null;
        return `${record.inventory.lotNumber}${
          props.permissions === "provider"
            ? ` - Quantity: 
            ${record?.inventory?.distributedInventories_aggregate?.aggregate.count}`
            : ""
        }`;
      }}
      tertiaryText={(record) => {
        if (!record?.inventory) return null;
        if (record.used) {
          return "Used";
        }
        if (record.lostDate) {
          return "Lost";
        }
        if (record.returnedDate) {
          return "Returned";
        }
        if (expired(record.inventory.expiryDate) && !record.returnedDate) {
          return <span style={{ color: "red" }}>Expired - Not returned</span>;
        }
        if (expired(record.inventory.expiryDate) && record.returnedDate) {
          return "Expired - returned";
        } else {
          return `Expires: ${format(
            record.inventory.expiryDate,
            "MMM DD, YYYY",
          )}`;
        }
      }}
      linkType={(record) => {
        if (!record?.inventory) return null;
        return props.permissions === "provider" &&
          !record?.used &&
          !record?.returnedDate &&
          !record?.lostDate
          ? "edit"
          : "show";
      }}
      rowStyle={props.rowStyle}
    />
  );
};
