import { makeStyles } from "@material-ui/core/styles";

export const useDashboardCardStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minWidth: 275,
    maxWidth: 350,
    margin: "16px 12px 0 12px",
    paddingBottom: 5,
    paddingLeft: 2,
    boxShadow: "0px 0px 5px 1px rgba(49, 39, 131, 0.25)",
  },
}));

export const useCardButtonStyles = makeStyles((theme) => ({
  label: {
    textAlign: "center",
    "&:not(:first-child)": {
      maxWidth: "50%",
    },
  },
}));
