import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const UsersFilter = [
  <TextInput
    source="firstName@_ilike,lastName@_ilike,email@_ilike"
    label="Search by name or email"
    alwaysOn
    resettable
  />,
  <SelectInput
    source="role"
    choices={[
      { id: "admin", name: "Admin" },
      { id: "provider", name: "Provider" },
      { id: "pharmacy", name: "Pharmacy" },
      // { id: "auditor", name: "Auditor" },
    ]}
    label="Search by Role"
    emptyText="Show All"
    alwaysOn
  />,
  <SelectInput
    source="providerType"
    choices={[
      { id: "als", name: "ALS" },
      { id: "bls", name: "BLS" },
    ]}
    label="Provider Type"
    emptyText="ALS & BLS"
    alwaysOn
  />,
];

export const UserList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="Users"
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />}
      perPage={25}
      filters={UsersFilter}
      sort={{
        field: "role,firstName",
        order: "desc,asc",
      }}
    >
      <Datagrid>
        <FunctionField
          render={(record) => `${record.firstName} ${record.lastName}`}
          label="Name"
        />
        <FunctionField
          render={(record) =>
            `${record.role.charAt(0).toUpperCase() + record.role.slice(1)}${
              record.providerType
                ? " - " + record.providerType.toUpperCase()
                : ""
            }`
          }
          label="Role"
        />
        <TextField source="email" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
