import Button from "@material-ui/core/Button";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { theme } from "../App";
import DrugProtocols from "../assets/DrugProtocols.pdf";
import NauseaAndAnalgesiaProtocols from "../assets/NauseaAndAnalgesiaProtocols.pdf";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 10,
    display: "grid",
    gap: 15,
    maxWidth: 325,
  },
}));

const ProtocolsView = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <h1>Medical Directives</h1>

      <Button variant="contained" color="secondary" href={DrugProtocols}>
        BLS 2020 Handbook
      </Button>

      <Button
        variant="contained"
        color="secondary"
        href={NauseaAndAnalgesiaProtocols}
      >
        Nausea and Analgesia Protocols
      </Button>
    </div>
  );
};

const Protocols = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <ProtocolsView {...props} />
  </ThemeProvider>
);

export default Protocols;
