import { gql, useMutation, useQuery } from "@apollo/client";
import { Chip, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
import { addMonths, format, startOfMonth, subMonths } from "date-fns";
import { useCallback, useState } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FunctionField,
  List,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField as TextFieldRA,
  TextInput,
  Toolbar,
  useGetIdentity,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import { Link } from "react-router-dom";
import { authGQLClient } from "../apolloClient";

const INVENTORY_AGGREGATE_DISTRIBUTED = gql`
  query distributedInventory_aggregate($inventoryId: Int) {
    distributedInventory_aggregate(
      where: { inventoryId: { _eq: $inventoryId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INVENTORY_AGGREGATE_USED = gql`
  query distributedInventory_aggregate($inventoryId: Int) {
    distributedInventory_aggregate(
      where: {
        inventoryId: { _eq: $inventoryId }
        _and: { _and: { used: { _eq: true } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INVENTORY_AGGREGATE_RETURNED = gql`
  query distributedInventory_aggregate($inventoryId: Int) {
    distributedInventory_aggregate(
      where: {
        inventoryId: { _eq: $inventoryId }
        _and: { _and: { returnedDate: { _is_null: false } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const INVENTORY_AGGREGATE_LOST = gql`
  query distributedInventory_aggregate($inventoryId: Int) {
    distributedInventory_aggregate(
      where: {
        inventoryId: { _eq: $inventoryId }
        _and: { _and: { lostDate: { _is_null: false } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ReturnToPharmacyButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [returnedToPharmacyDate, setReturnedToPharmacyDate] = useState(
    format(record.returnedToPharmacyDate || new Date(), "YYYY-MM-DD"),
  );
  const [returnedToPharmacyQuantity, setReturnedToPharmacyQuantity] = useState(
    record.returnedToPharmacyQuantity || record.quantity,
  );
  const [returnedToPharmacyNotes, setReturnedToPharmacyNotes] = useState(
    record.returnedToPharmacyNotes || "",
  );

  const [updateRecord, { loading }] = useUpdate(
    "inventory",
    record.id,
    {
      returnedToPharmacyDate: new Date(returnedToPharmacyDate),
      returnedToPharmacyQuantity,
      returnedToPharmacyNotes,
    },
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
      },
      onFailure: (error) => {
        console.error(error);
        notify("Error: inventory not updated", "warning");
      },
    },
    record,
  );
  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Return To Pharmacy"
      >
        <DialogTitle>Return To Pharmacy</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateRecord();
          }}
        >
          <>
            <DialogContent>
              <div style={{ alignSelf: "center", padding: 15 }}>
                <TextField
                  type="date"
                  label="Date Returned"
                  required
                  value={returnedToPharmacyDate}
                  onChange={(e) => setReturnedToPharmacyDate(e.target.value)}
                  fullWidth
                />
              </div>
              <div style={{ alignSelf: "center", padding: 15 }}>
                <TextField
                  type="number"
                  label="Quantity Returned"
                  required
                  value={returnedToPharmacyQuantity}
                  onChange={(e) =>
                    setReturnedToPharmacyQuantity(e.target.value)
                  }
                  fullWidth
                />
              </div>
              <div style={{ alignSelf: "center", padding: 15 }}>
                <TextField
                  type="text"
                  label="Notes"
                  value={returnedToPharmacyNotes}
                  onChange={(e) => setReturnedToPharmacyNotes(e.target.value)}
                  fullWidth
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !returnedToPharmacyQuantity || !returnedToPharmacyDate
                }
              >
                Save
              </Button>
            </DialogActions>
          </>
        </form>
      </Dialog>

      <Button
        disabled={loading}
        onClick={() => setShowDialog(true)}
        variant="outlined"
        color="secondary"
      >
        Return to Pharmacy
      </Button>
    </>
  );
};

const InventoryDetails = ({ record, permissions }) => {
  const {
    loading: totalLoading,
    error: totalError,
    data: totalData,
  } = useQuery(INVENTORY_AGGREGATE_DISTRIBUTED, {
    variables: { inventoryId: record.id },
    client: authGQLClient,
  });
  const {
    loading: usedLoading,
    error: usedError,
    data: usedData,
  } = useQuery(INVENTORY_AGGREGATE_USED, {
    variables: { inventoryId: record.id },
    client: authGQLClient,
  });

  const {
    loading: returnedLoading,
    error: returnedError,
    data: returnedData,
  } = useQuery(INVENTORY_AGGREGATE_RETURNED, {
    variables: { inventoryId: record.id },
    client: authGQLClient,
  });

  const {
    loading: lostLoading,
    error: lostError,
    data: lostData,
  } = useQuery(INVENTORY_AGGREGATE_LOST, {
    variables: { inventoryId: record.id },
    client: authGQLClient,
  });

  if (totalLoading || usedLoading || returnedLoading || lostLoading)
    return "Loading...";
  if (totalError || usedError || returnedError || lostError)
    return `Error! ${
      (totalError || usedError || returnedError || lostError).message
    }`;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        opacity: 0.7,
        alignItems: "center",
      }}
    >
      <div style={{ padding: 5, textAlign: "center" }}>
        <div>Distributed: </div>
        <Link
          to={{
            pathname: "/distributedInventory",
            search: `displayedFilters={}&filter={"inventoryId":${record.id}}`,
          }}
        >
          {totalData.distributedInventory_aggregate.aggregate.count}
        </Link>
      </div>
      <div style={{ padding: 5, textAlign: "center" }}>
        <div>Used: </div>
        <Link
          to={{
            pathname: "/distributedInventory",
            search: `displayedFilters={}&filter={"inventoryId":${record.id},"used":true}`,
          }}
        >
          {usedData.distributedInventory_aggregate.aggregate.count}
        </Link>
      </div>
      <div style={{ padding: 5, textAlign: "center" }}>
        <div>Returned: </div>
        <Link
          to={{
            pathname: "/distributedInventory",
            search: `displayedFilters={}&filter={"inventoryId":${
              record.id
            },"returnedDate":${JSON.stringify({
              format: "hasura-raw-query",
              value: { _is_null: false },
            })}}`,
          }}
        >
          {returnedData.distributedInventory_aggregate.aggregate.count}
        </Link>
      </div>
      <div style={{ padding: 5, textAlign: "center" }}>
        <div>Lost: </div>
        <Link
          to={{
            pathname: "/distributedInventory",
            search: `displayedFilters={}&filter={"inventoryId":${
              record.id
            },"lostDate":${JSON.stringify({
              format: "hasura-raw-query",
              value: { _is_null: false },
            })}}`,
          }}
        >
          {lostData.distributedInventory_aggregate.aggregate.count}
        </Link>
      </div>
      {permissions === "admin" && (
        <div>
          {record.returnedToPharmacyDate ? (
            <div style={{ padding: 5, textAlign: "center" }}>
              <div>
                <b>Returned To Pharmacy:</b>
              </div>
              <DateField
                source="returnedToPharmacyDate"
                options={{
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }}
              />
              <div>
                Quantity: <TextFieldRA source="returnedToPharmacyQuantity" />
              </div>
              <div>
                Notes: <TextFieldRA source="returnedToPharmacyNotes" />
              </div>
            </div>
          ) : (
            <ReturnToPharmacyButton record={record} />
          )}
        </div>
      )}
    </div>
  );
};

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    fontSize: 14,
    fontWeight: 600,
    padding: 5,
    margin: theme.spacing(1),
    opacity: 0.6,
  },
  selected: { opacity: 1 },
}));

const getFilterValues = (filter) => {
  switch (filter) {
    case "this month":
      return {
        _gt: startOfMonth(new Date()),
        _lt: startOfMonth(addMonths(new Date(), 1)),
      };
    case "last month":
      return {
        _lt: startOfMonth(new Date()),
        _gt: startOfMonth(subMonths(new Date(), 1)),
      };
    case "next month":
      return {
        _gt: startOfMonth(addMonths(new Date(), 1)),
        _lt: startOfMonth(addMonths(new Date(), 2)),
      };
    case "expired":
      return {
        _lt: new Date(),
      };
    default:
      return {};
  }
};

const InventoryFilters = [
  <TextInput
    key="lotNumber"
    source="lotNumber@_ilike"
    label="Search by Lot Number"
    alwaysOn
    resettable
  />,
  <TextInput
    key="medication"
    source="medication#name@_ilike,medication#concentration@_ilike"
    label="Search by Medication"
    alwaysOn
    resettable
  />,
  <SelectInput
    key="controlledSubstances"
    source="medication#controlledSubstance"
    choices={[
      { id: true, name: "Controlled Substances" },
      { id: false, name: "Non-Controlled Substances" },
    ]}
    label="Controlled Substance"
    emptyText="Show All"
    alwaysOn
    style={{ width: 200 }}
  />,
  <SelectInput
    key="blsRestricted"
    source="medication#blsRestricted"
    choices={[
      { id: true, name: "BLS Restricted" },
      { id: false, name: "BLS Non-Restricted" },
    ]}
    label="BLS Restricted"
    emptyText="Show All"
    alwaysOn
    style={{ width: 200 }}
  />,
];

export const InventoryList = (props) => {
  const classes = useQuickFilterStyles();
  const [filter, setFilter] = useState(null);

  return (
    <>
      <div>
        <Chip
          className={`${classes.chip} ${
            filter === "this month" && classes.selected
          }`}
          onClick={() =>
            setFilter(filter === "this month" ? null : "this month")
          }
          clickable
          label="Expires This Month"
          {...(filter === "this month" && {
            deleteIcon: <DoneIcon />,
            onDelete: () => setFilter(null),
          })}
        />
        <Chip
          className={`${classes.chip} ${
            filter === "next month" && classes.selected
          }`}
          onClick={() =>
            setFilter(filter === "next month" ? null : "next month")
          }
          clickable
          label="Expires Next Month"
          {...(filter === "next month" && {
            deleteIcon: <DoneIcon />,
            onDelete: () => setFilter(null),
          })}
        />
        <Chip
          className={`${classes.chip} ${
            filter === "expired" && classes.selected
          }`}
          onClick={() => setFilter(filter === "expired" ? null : "expired")}
          label="Expired"
          {...(filter === "expired" && {
            deleteIcon: <DoneIcon />,
            onDelete: () => setFilter(null),
          })}
        />
        <Chip
          className={`${classes.chip} ${
            filter === "last month" && classes.selected
          }`}
          onClick={() =>
            setFilter(filter === "last month" ? null : "last month")
          }
          label="Expired Last Month"
          {...(filter === "last month" && {
            deleteIcon: <DoneIcon />,
            onDelete: () => setFilter(null),
          })}
        />
      </div>
      <List
        {...props}
        exporter={false}
        title="Medication Inventory"
        bulkActionButtons={false}
        actions={null}
        sort={{ field: "created_at", order: "DESC" }}
        filter={{
          expiryDate: {
            format: "hasura-raw-query",
            value: getFilterValues(filter),
          },
        }}
        filters={InventoryFilters}
        pagination={
          <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
        }
        perPage={25}
      >
        <Datagrid expand={<InventoryDetails {...props} />}>
          <TextFieldRA source="lotNumber" />
          <TextFieldRA source="quantity" />
          <ReferenceField
            label="Medication"
            source="medicationId"
            reference="medications"
            link={false}
          >
            <FunctionField
              render={(medication) =>
                `${medication.name} - ${medication.concentration}`
              }
            />
          </ReferenceField>
          <DateField
            source="expiryDate"
            options={{
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
          />
          <DateField
            source="created_at"
            label="Date added"
            options={{
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
          />
          {props.permissions === "admin" && <EditButton />}
        </Datagrid>
      </List>
    </>
  );
};

const INSERT_INVENTORY = gql`
  mutation insert_inventory($objects: [inventory_insert_input!]!) {
    data: insert_inventory(objects: $objects) {
      returning {
        created_at
        id
      }
    }
  }
`;

const FormSaveToolbar = ({ invalid, ...rest }) => (
  <Toolbar {...rest}>
    <SaveButton disabled={invalid} />
  </Toolbar>
);

const EditSaveToolBar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const InventoryCreate = (props) => {
  const { identity } = useGetIdentity();
  const redirectTo = useRedirect();
  const [addInventory] = useMutation(INSERT_INVENTORY, {
    client: authGQLClient,
  });
  const notify = useNotify();

  const save = useCallback(
    async (values) => {
      try {
        const addedInventory = await addInventory({
          variables: {
            objects: {
              createdById: identity?.id,
              createdByRole: identity?.role,
              expiryDate: values.expiryDate,
              lotNumber: values.lotNumber,
              medicationId: values.medicationId,
              quantity: values.quantity,
            },
          },
        });
        if (addedInventory?.data?.data?.returning?.length) {
          notify("Medication Added To Inventory");
          redirectTo("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [addInventory, identity?.id, identity?.role, notify, redirectTo],
  );

  return (
    <Create {...props} title="Add Medication to Inventory">
      <SimpleForm save={save} toolbar={<FormSaveToolbar />}>
        <TextInput
          source="lotNumber"
          autoComplete="off"
          validate={[required()]}
        />
        <DateInput source="expiryDate" validate={[required()]} />
        <NumberInput source="quantity" validate={[required()]} />
        <ReferenceInput
          label="Medication"
          source="medicationId"
          reference="medications"
          validate={[required()]}
          perPage={150}
          sort={{ field: "name", order: "ASC" }}
          {...(identity?.role === "pharmacy"
            ? { filter: { controlledSubstance: true } }
            : {})}
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput
            optionText={(medication) => {
              return `${medication?.name || ""} - ${
                medication?.concentration || ""
              }`;
            }}
            validate={[required()]}
            shouldRenderSuggestions={(value) => value?.length}
            resettable
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const InventoryEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Edit
      {...props}
      title="Edit Inventory"
      onSuccess={() => {
        notify("Inventory Updated");
        redirect("/inventory");
      }}
      undoable={false}
    >
      <SimpleForm toolbar={<EditSaveToolBar {...props} />}>
        <TextInput
          source="lotNumber"
          autoComplete="off"
          validate={[required()]}
        />
        <DateInput source="expiryDate" validate={[required()]} />
        <NumberInput source="quantity" validate={[required()]} />
        <ReferenceInput
          label="Medication"
          source="medicationId"
          reference="medications"
          validate={[required()]}
          perPage={150}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput
            optionText={(medication) => {
              return `${medication?.name || ""} - ${
                medication?.concentration || ""
              }`;
            }}
            validate={[required()]}
            shouldRenderSuggestions={(value) => value?.length}
            resettable
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
