import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { useCardButtonStyles, useDashboardCardStyles } from "./styles";

const AdminDashboard = () => {
  const dashboardCardClasses = useDashboardCardStyles();
  const cardButtonClasses = useCardButtonStyles();

  return (
    <div className={dashboardCardClasses.container}>
      <Card className={dashboardCardClasses.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Assign medication to a provider
          </Typography>
          <Typography component="div" color="textSecondary">
            Distribute a controlled substance to an ALS provider or a restricted
            medication to a BLS provider
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/distributedInventory/create`}
            size="large"
            color="secondary"
            variant="contained"
          >
            Assign Medication
          </Button>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/distributedInventory`}
            size="large"
            color="secondary"
            variant="outlined"
          >
            Distributed Medications
          </Button>
        </CardActions>
      </Card>
      <Card className={dashboardCardClasses.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Add medication to inventory
          </Typography>
          <Typography component="div" color="textSecondary">
            Add medication to Hatzoloh's inventory, by entering the lot number,
            expiry date and the quantity of medication available in the lot
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/inventory/create`}
            size="large"
            color="secondary"
            variant="contained"
          >
            Add Inventory
          </Button>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/inventory/show`}
            size="large"
            color="secondary"
            variant="outlined"
          >
            View Inventory
          </Button>
        </CardActions>
      </Card>
      <Card className={dashboardCardClasses.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Users
          </Typography>
          <Typography component="div" color="textSecondary">
            View and add providers, pharmacies, admins and auditors
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/users`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Providers
          </Button>
        </CardActions>
      </Card>
      <Card className={dashboardCardClasses.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Medications
          </Typography>
          <Typography component="div" color="textSecondary">
            View and add the medications that Hatzoloh tracks through this
            system
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/medications`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Medications
          </Button>
        </CardActions>
      </Card>
      <Card className={dashboardCardClasses.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Medical Directives
          </Typography>
          <Typography component="div" color="textSecondary">
            View Hatzoloh's medical directives
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={cardButtonClasses.label}
            component={Link}
            to={`/protocols`}
            size="large"
            color="secondary"
            variant="contained"
          >
            View Directives
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default AdminDashboard;
