import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  AutocompleteInput,
  Create,
  minValue,
  number,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetIdentity,
  useNotify,
  useRedirect,
} from "react-admin";
import { useField } from "react-final-form";
import { authGQLClient } from "../../apolloClient";

const NumberInput = ({ name, label }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(name);
  return (
    <TextInput
      name={name}
      label={label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={[number(), minValue(1), required()]}
      min="1"
      type="number"
      variant="filled"
      id="filled-required"
    />
  );
};

const INSERT_DISTRIBUTED_INVENTORY = gql`
  mutation insert_distributedInventory(
    $objects: [distributedInventory_insert_input!]!
  ) {
    data: insert_distributedInventory(objects: $objects) {
      returning {
        created_at
        id
      }
    }
  }
`;

const validateForm = (values) => {
  const errors = {};
  if (!values.inventoryId) {
    errors.inventoryId = "Medication Lot Number Required";
  }
  if (!values.providerId) {
    // You can return translation keys
    errors.providerId = "ra.validation.required";
  }
  if (Number(values?.quantity) < 1) {
    errors.providerId = {
      message: "ra.validation.minValue",
      args: { min: 1 },
    };
  }
  return errors;
};

const FormSaveToolbar = ({ invalid, ...rest }) => (
  <Toolbar {...rest}>
    <SaveButton disabled={invalid} />
  </Toolbar>
);

export const DistributedInventoryCreate = (props) => {
  const { identity } = useGetIdentity();
  const redirectTo = useRedirect();
  const [addDistributedInventory] = useMutation(INSERT_DISTRIBUTED_INVENTORY, {
    client: authGQLClient,
  });

  const notify = useNotify();
  const save = useCallback(
    async (values) => {
      try {
        const addedInventory = await addDistributedInventory({
          variables: {
            objects: Array(Number(values.quantity)).fill({
              createdById: identity?.id,
              createdByRole: identity?.role,
              inventoryId: values.inventoryId,
              providerId: values.providerId,
            }),
          },
        });
        if (addedInventory?.data?.data?.returning?.length) {
          notify("Medications assigned");
          redirectTo("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [addDistributedInventory, identity?.id, identity?.role, notify, redirectTo],
  );

  return (
    <Create
      {...props}
      title={
        identity?.role === "provider"
          ? "Add Medication"
          : "Distribute Inventory"
      }
    >
      <SimpleForm
        save={save}
        validate={validateForm}
        toolbar={<FormSaveToolbar />}
      >
        <ReferenceInput
          label="Provider"
          source="providerId"
          reference="users"
          validate={[required()]}
          perPage={100}
          filter={{ role: "provider" }}
          {...(identity?.role === "provider"
            ? { filter: { id: identity?.id } }
            : {})}
        >
          <SelectInput
            optionText={(user) => `${user.firstName} ${user.lastName}`}
            {...(identity?.role === "provider"
              ? { initialValue: identity?.id, disabled: true }
              : { resettable: true })}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Medication Lot Number"
          source="inventoryId"
          reference="inventory"
          validate={[required()]}
          perPage={150}
          {...(identity?.role === "provider"
            ? {
                filter: {
                  "medication#controlledSubstance": false,
                  ...(identity?.providerType === "als"
                    ? {}
                    : { "medication#blsRestricted": false }),
                },
              }
            : {})}
          filterToQuery={(searchText) => ({ lotNumber: searchText })}
        >
          <AutocompleteInput
            optionText="lotNumber"
            resettable
            validate={[required()]}
            shouldRenderSuggestions={(value) => value?.length}
          />
        </ReferenceInput>
        <NumberInput name="quantity" label="Quantity" />
      </SimpleForm>
    </Create>
  );
};
