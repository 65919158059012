import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";

const AuditorDashboard = () => {
  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>Auditor Dashboard</CardContent>
    </Card>
  );
};

export default AuditorDashboard;
