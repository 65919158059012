import gql from "graphql-tag";
import { buildFields } from "@sbell/ra-data-hasura";
import { GET_LIST, GET_ONE } from "react-admin";

const GET_LIST_DISTRIBUTED_INVENTORY = gql`
  {
    createdById
    createdByRole
    created_at
    id
    inventoryId
    providerId
    returned
    returnedDate
    lostDate
    updatedById
    updatedByRole
    updated_at
    used
    usedDate
    usedDosage
    usedProtocol
    usedRunNumber
    wasted
    wastedAmount
    wastedDate
    wastedDosage
    wastedReason
    inventory {
      id
      lotNumber
      expiryDate
      medication {
        id
        name
        concentration
        controlledSubstance
        blsRestricted
      }
      distributedInventories_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
    user {
      id
      firstName
      lastName
      role
      providerType
    }
  }
`;

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections;
};

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES = {
  distributedInventory: {
    [GET_LIST]: GET_LIST_DISTRIBUTED_INVENTORY,
    [GET_ONE]: GET_LIST_DISTRIBUTED_INVENTORY,
  },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
