import { createMuiTheme } from "@material-ui/core/styles";
import buildHasuraProvider from "@sbell/ra-data-hasura";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { Admin, Loading, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { authGQLClient } from "./apolloClient";
import { authProvider } from "./authProvider";
import Dashboard from "./components/Dashboard";
import {
  DistributedInventoryCreate,
  DistributedInventoryEdit,
  DistributedInventoryList,
  DistributedInventoryShow,
} from "./components/DistributedInventory";
import {
  InventoryCreate,
  InventoryEdit,
  InventoryList,
} from "./components/Inventory";
import LoginPage from "./components/Login";
import {
  MedicationCreate,
  MedicationEdit,
  MedicationList,
} from "./components/Medications";
import PasswordReset from "./components/PasswordReset";
import Protocols from "./components/Protocols";
import { UserCreate, UserEdit, UserList } from "./components/Users";
import customBuildFields from "./customBuildFields";
import { FirebaseApp } from "./firebase";

export const history = createBrowserHistory();

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e30613",
    },
    secondary: {
      main: "#312783",
    },
    error: {
      main: "#EB6673",
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        borderLeft: "3px solid #4f3cc9",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#4f3cc9",
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor:
          process.env.REACT_APP_STAGE === "local" ? "#800080" : "#e30613",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#312783",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: "none",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const customRoutes = [
  <Route exact path="/password-reset" component={PasswordReset} noLayout />,
  <Route exact path="/protocols" component={Protocols} />,
];

const App = () => {
  const [resolvedDataProvider, setResolvedDataProvider] = useState();

  useEffect(() => {
    FirebaseApp()
      .auth()
      .onAuthStateChanged(() => {
        buildHasuraProvider(
          {
            client: authGQLClient,
          },
          {
            buildFields: customBuildFields,
          },
        )
          .then((dp) => setResolvedDataProvider(() => dp))
          .catch((error) => {
            console.log(error);
          });
      });
  }, []);

  if (!resolvedDataProvider) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Getting everything together"
      />
    );
  }

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={resolvedDataProvider}
      customRoutes={customRoutes}
      history={history}
      theme={theme}
      loginPage={LoginPage}
      title="Hatzoloh Medication Management"
      dashboard={Dashboard}
    >
      {(permissions) => {
        return [
          <Resource
            name="inventory"
            list={
              permissions === "admin" ||
              permissions === "pharmacy" ||
              permissions === "auditor"
                ? InventoryList
                : null
            }
            create={
              permissions === "admin" || permissions === "pharmacy"
                ? InventoryCreate
                : null
            }
            edit={permissions === "admin" ? InventoryEdit : null}
            options={{ label: "Inventory" }}
          />,
          <Resource
            name="distributedInventory"
            list={
              permissions === "admin" ||
              permissions === "provider" ||
              permissions === "auditor"
                ? DistributedInventoryList
                : null
            }
            create={
              permissions === "admin" || permissions === "provider"
                ? DistributedInventoryCreate
                : null
            }
            edit={
              permissions === "admin" || permissions === "provider"
                ? DistributedInventoryEdit
                : null
            }
            show={
              permissions === "admin" ||
              permissions === "auditor" ||
              permissions === "provider"
                ? DistributedInventoryShow
                : null
            }
            options={{
              label:
                permissions === "provider"
                  ? "My Medications"
                  : "Distributed Inventory",
            }}
          />,
          <Resource
            name="medications"
            list={
              permissions === "admin" || permissions === "auditor"
                ? MedicationList
                : null
            }
            create={permissions === "admin" ? MedicationCreate : null}
            edit={permissions === "admin" ? MedicationEdit : null}
          />,
          <Resource
            name="users"
            list={
              permissions === "admin" || permissions === "auditor"
                ? UserList
                : null
            }
            create={permissions === "admin" ? UserCreate : null}
            edit={permissions === "admin" ? UserEdit : null}
          />,
        ];
      }}
    </Admin>
  );
};

export default App;
