import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AdminDashboard from "./Admin";
import AuditorDashboard from "./Auditor";
import PharmacyDashboard from "./Pharmacy";
import ProviderDashboard from "./Provider";

const Dashboard = ({ permissions }) => {
  const search = useLocation().search;
  const reload = new URLSearchParams(search).get("reload");

  useEffect(() => {
    if (!!reload) {
      window.location.reload();
      window.location.replace("/");
    }
  }, [reload]);

  if (permissions === "provider") {
    return <ProviderDashboard />;
  } else if (permissions === "admin") {
    return <AdminDashboard />;
  } else if (permissions === "auditor") {
    return <AuditorDashboard />;
  } else if (permissions === "pharmacy") {
    return <PharmacyDashboard />;
  } else {
    return null;
  }
};

export default Dashboard;
